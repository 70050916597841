/**@jsx jsx */
import { SEO } from '@components/SEO'
import { ContentfulResource, ContentfulAsset } from 'graphqlTypes'
import { Text, Heading, Box, jsx, Flex } from 'theme-ui'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout } from '@components/Layout'
import { getRichText } from '@components/ContentfulRich'
import { DisciplineBoxes } from '@components/Resources'
import LongArrowLeft from '../assets/long-arrow-left.svg'

const Resource = ({
  data,
}: {
  data: { resource: ContentfulResource; images: ContentfulAsset[] }
}) => {
  const imageNodes = data?.images?.edges ?? []
  const images = imageNodes.map(edge => edge.node.fixed)

  return (
    <Layout page={`publication-${data?.resource?.slug}`}>
      <SEO title={`MIH - ${data?.resource.title} ?? 'Resource`} />
      <Box
        sx={{
          m: [2, 5],
        }}
      >
        <Link to="/resources" style={{ textDecoration: 'none' }}>
          <Flex>
            <LongArrowLeft
              style={{
                marginTop: '18px',
              }}
              width={24}
              height={32}
            />
            <Text
              sx={{
                color: 'grey',
                fontFamily: 'Futura Medium',
                fontSize: [5],
                m: [3],
              }}
            >
              Back
            </Text>
          </Flex>
        </Link>
        <Box
          sx={{
            m: [3],
          }}
        >
          <Heading>{data.resource.title}</Heading>
          <Box
            sx={{
              mt: [2],
            }}
          >
            <Link
              to={`/about/${data?.resource?.authorAbout?.slug ??
                data?.resource?.author}`}
              activeClassName="active"
              sx={{
                color: 'primary.one',
              }}
            >
              {data?.resource?.authorAbout?.name ?? data.resource.author.name}
            </Link>
          </Box>

          {data?.resource?.disciplines && (
            <DisciplineBoxes disciplines={data.resource.disciplines} />
          )}
          <Box
            sx={{
              mb: [2],
            }}
          >
            {getRichText(data.resource?.header?.json)}
          </Box>
          <Box>{getRichText(data.resource?.body?.json, images)}</Box>
        </Box>
        <MediaResources resource={data.resource} />
      </Box>
    </Layout>
  )
}

const MediaResources = ({ resource }) => {
  const Media = ({ media }) => {
    if (media?.file?.contentType.includes('image')) {
      return (
        <Box
          sx={{
            p: [2],
            display: 'inline-block',
            minWidth: '500px',
            minHeight: '500px',
          }}
        >
          <Img fluid={media.fluid} />
        </Box>
      )
    } else if (media?.file?.contentType.includes('video')) {
      return (
        <Box>
          <Video url={media?.file?.url} type={media?.file?.contentType} />
        </Box>
      )
    } else if (media?.file?.contentType.includes('pdf')) {
      return (
        <Box>
          <a href={media.file.url}>Download the PDF</a>
        </Box>
      )
    } else {
      return (
        <Box>
          {media?.file?.url && <a href={media.file.url}>Download the File</a>}
        </Box>
      )
    }
  }
  return resource?.displayMedia !== false ? (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        m: [3],
      }}
    >
      {!!resource?.media &&
        resource.media.map(media => (
          <Flex key={media?.contentful_id}>
            <Media media={media} />
          </Flex>
        ))}
    </Flex>
  ) : null
}

const Video = ({ url, type }) => {
  return (
    <video controls>
      <source src={url} type={type} />
    </video>
  )
}

export default Resource

export const query = graphql`
  query ResourcePageQuery($slug: String!, $images: [String!]!) {
    resource: contentfulResource(slug: { eq: $slug }) {
      id
      author
      disciplines
      title
      slug
      authorAbout {
        slug
        name
      }
      coverImage {
        fixed(width: 300, height: 300) {
          ...GatsbyContentfulFixed
        }
      }
      body {
        json
      }
      header {
        json
      }
      displayMedia
      media {
        contentful_id
        file {
          contentType
          url
        }
        fluid(maxWidth: 1000, maxHeight: 1000) {
          ...GatsbyContentfulFluid
        }
      }
    }
    images: allContentfulAsset(filter: { file: { url: { in: $images } } }) {
      edges {
        node {
          fixed(width: 500, height: 500) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`
