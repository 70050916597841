import React from 'react'
import { Text, Flex, Link } from 'theme-ui'
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'

const nodeRenderer = ({ images }) => ({
  renderMark: {
    [MARKS.BOLD]: text => (
      <Text sx={{ fontFamily: 'Futura Medium Italic', color: 'black' }}>
        {text}
      </Text>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Text
          sx={{
            fontFamily: 'Futura Book Regular',
            my: [3],
            color: 'grey',
          }}
        >
          {children}
        </Text>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target) {
        const file = node?.data?.target?.fields?.file

        if (!file) {
          return <Flex />
        }

        const image = images.find(
          image => image?.src?.split('?')[0] === file['en-US']?.url
        )

        return (
          <Flex
            sx={{
              justifyContent: 'center',
            }}
          >
            {image && <Img fixed={image} />}
          </Flex>
        )
      }
    },
    // TODO: Display as embedded video if youtube url
    // [INLINES.HYPERLINK]: (node, children) => {
    //   return <a href={node?.data?.uri}>{children}</a>
    // }
  },
})

export const getRichText = (document, images?) => {
  return documentToReactComponents(document, nodeRenderer({ images }))
}
